@import '../../../assets/sass/style';

.intro {
  text-transform: capitalize;
  margin: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 10% 0;
  position: relative;
  h2 {
    position: relative;
    z-index: 1;
    font-size: 36px;
    text-transform: uppercase;
    color: white; }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: rgba(0, 0, 0, .5);
    transition: .5s; } }

.cities {
  a {
    text-decoration: none; }
  .intro {
    &:hover {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        background: rgba(0, 0, 0, .7); } } } }

h3 {
  text-align: left;
  padding: 0 20px;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.city-items-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  a {
    flex: 0 0 25%;
    max-width: 25%;
    text-decoration: none;
    img {
      min-width: 100%;
      min-height: 200px;
      height: auto;
      width: 100%; } }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    a {
      flex: 0 0 100%;
      max-width: 100%; } }
  .city-item {
    background: $darken-color;
    &-img {
      display: flex;
      align-items: center;
      height: 200px;
      overflow: hidden;
      position: relative;
      img {
        transition: .2s; }
      &:hover {
        img {
          transform: scale(1.2); } }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5)); } }
    &-category {
      font-size: 12px;
      position: absolute;
      bottom: 5px;
      right: 5px;
      color: white;
      text-transform: uppercase;
      z-index: 1; }
    h4 {
      margin: 0;
      padding: 3% 0;
      text-decoration: none;
      color: $light-color; } } }
