@import '../../../../../../assets/sass/style';

.rating {
  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 30px; }
  &-display {
    font-size: 26px;
    margin-bottom: 30px;
    width: 100%; }
  &-field {
    width: calc(33.33% - 40px);
    background: linear-gradient($darken-color, lighten($darken-color, 2));
    text-align: left;
    padding: 15px;
    margin: 0 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin: 5px 0; }
    h4 {
      font-size: 20px;
      margin: 0 0 15px; }
    > div {
      margin-top: 10px; }
    span {
      font-size: 10px;
      display: block;
      margin-bottom: 2px; }
    .progressbar {
      height: 2px;
      margin-top: 2px;
      background: linear-gradient(to right, $medium-color, lighten($medium-color, 5)); } } }
