@import '../../assets/sass/style';

.placeautocomplete {
  ul {
    width: calc(35% - 50px);
    margin: 0 auto;
    background: darken($darken-color, 2);
    list-style: none;
    padding: 0;
    border-radius: 0 0 10px 10px;
    li {
      padding: 15px 0;
      cursor: pointer;
      transition: .2s;
      &:hover {
        background: lighten($darken-color, 5); } } } }
