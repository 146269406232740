@import "../../../../../assets/sass/style";

.add-feedback {
  background: $darken-color;
  margin-top: 30px;
  >div>div {
    padding: 30px; }
  h3 {
    padding: 0;
    margin-top: 0; }
  form {
    display: flex;
    flex-wrap: wrap;
    >div {
      width: 25%;
      @media only screen and (max-width: 768px) {
        width: 100%; }
      >div {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 25px;
        padding: 0 5px;
        >div {
          position: relative; }
        p {
          width: 100%;
          text-align: left; } } }
    .rating {
      width: 100%;
      >div {
        justify-content: center; } } }

  input[type=radio] {
    position: absolute;
    visibility: hidden; }

  label {
    display: block;
    position: relative;
    font-weight: 300;
    padding: 0 25px;
    margin: 10px auto;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
    text-align: left; }

  .check {
    display: block;
    position: absolute;
    border: 2px solid #AAAAAA;
    border-radius: 100%;
    height: 14px;
    width: 14px;
    top: 10px;
    left: 0;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear; }

  .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 8px;
    width: 8px;
    top: 7px;
    left: 7px;
    transform: translateX(-50%) translateY(-50%);
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear; }

  input[type=radio]:checked ~ .check {
    border: 2px solid $lighten-color; }

  input[type=radio]:checked ~ .check::before {
    background: $lighten-color; }

  input[type=radio]:checked ~ label {
    color: $lighten-color; }

  .stars-rating-input {
    flex-direction: row-reverse;
    span {
      float: right;
      position: relative;
      input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0; } }
    label {
      display: inline-block;
      width: 30px;
      height: 30px;
      text-align: center;
      color: #FFF;
      font-size: 30px;
      margin: 0;
      line-height: 30px;
      cursor: pointer;
      padding: 0 10px;
      &:after {
        font-family: "FontAwesome";
        content: "\f005";
        color: #ccc; } } }

  .stars-rating-input span:hover ~ span label:after,
  .stars-rating-input span:hover label:after,
  .stars-rating-input span.checked label:after,
  .stars-rating-input span.checked ~ span label:after {
    background: transparent;
    color: $lighten-color; }

  .create-button {
    width: 100%;
    display: flex;
    justify-content: center; }

  .open-review-message {
    cursor: pointer;
    span {
      text-decoration: underline;
      margin-left: 5px; } } }
