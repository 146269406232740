@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans&display=swap');

$dark-color: #10002b;
$darken-color: #240046;
$medium-color: #5a189a;
$lighten-color: #9d4edd;
$light-color: #e0aaff;

$primary-font: 'Open Sans', sans-serif;
$secondary-font: 'Montserrat', sans-serif;

body {
  background: $dark-color;
  color: $light-color;
  font-family: $secondary-font;
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  font-family: $primary-font; }

.btn {
  background: linear-gradient($medium-color, lighten($medium-color, 5));
  display: flex;
  align-items: center;
  border-radius: 50px;
  transition: .2s;
  padding: 10px 25px;
  text-decoration: none;
  font-size: 16px;
  font-family: $secondary-font;
  font-weight: 400;
  color: $light-color;
  border: none;
  cursor: pointer;
  &:hover {
    background: linear-gradient(lighten($medium-color, 5), lighten($medium-color, 10)); }
  &-secondary {
    display: inline-block;
    background: none;
    border: 2px solid $medium-color;
    font-size: 14px;
    padding: 5px 15px;
    margin: 0 10px;
    &:hover {
      background: $medium-color; } } }

.item {
  &-intro {
    display: flex;
    @media only screen and (max-width: 768px) {
      flex-direction: column; } }
  &-cover {
    width: 60%;
    height: 400px;
    overflow: hidden;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 768px) {
      width: 100%;
      height: 200px; }
    img {
      width: 100%;
      min-height: 100%;
      min-width: 100%; } }
  &-map {
    width: 40%;
    position: relative;
    @media only screen and (max-width: 768px) {
      width: 100%;
      height: 200px; } }
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px;
    div {
      display: flex;
      align-items: center; }
    span {
      font-size: 18px;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        margin-right: 5px; }
      &:last-child {
        margin-left: 15px; } }
    @media only screen and (max-width: 768px) {
      justify-content: center;
      flex-direction: column;
      div {
        flex-direction: column;
        .item-title-location {
          margin: 10px 0; } } } }
  &-description {
    margin: 0 30px;
    text-align: left; }
  &-config {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    button {
      margin: 0 10px; } } }

.input-main {
  width: 35%;
  border: none;
  padding: 15px 20px;
  border-radius: 50px;
  font-size: 18px;
  background: $darken-color;
  color: $light-color;
  -webkit-appearance: none;
  box-sizing: border-box;
  &:focus {
    outline: none; }
  &::placeholder {
    color: lighten($light-color, 5); } }

.create-page {
  text-align: center;
  margin-bottom: 5%;
  form {
    display: flex;
    flex-direction: column; }
  input[type="hidden"] {
    display: none; }
  h3 {
    display: block;
    text-align: center; }
  .btn {
    margin: 0 auto;
    &-secondary {
      margin: 0 10px; } }
  .image-uploader {
    display: none; }
  .image-uploader + label {
    cursor: pointer;
    width: 35%;
    display: block;
    margin: 0 auto; }
  .image-uploader:focus + label,
  .image-uploader + label:hover {
    background: linear-gradient(lighten($darken-color, 5), lighten($darken-color, 10)); }
  select {
    cursor: pointer; }
  .image-preview {
    width: 35%; }
  .image-preview-block {
    margin: 15px 0 30px 0; }
  .btn-edit {
    margin: 15px auto; } }

.event-page {
  margin-bottom: 2%; }
