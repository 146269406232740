.auth {
  padding: 7% 0;
  form {
    display: flex;
    flex-direction: column;
    button {
      margin: 0 auto;
      margin-top: 30px;
      margin-bottom: 10px; }
    input {
      margin: 5px 0;
      @media only screen and (max-width: 768px) {
        width: 80%; } } }
  .btn-secondary {
    margin-top: 5px; }
  #google-button {
    margin: 20px auto;
    margin-bottom: 30px;
    i {
      margin-right: 10px; } } }
