@import '../../assets/sass/style';

.welcome {
  &-intro {
    padding: 10% 0;
    background-size: cover;
    background-position: center;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(0, 0, 0, .5); }
    h1 {
      margin-bottom: 0;
      position: relative;
      font-size: 52px;
      color: white; }
    h2 {
      margin-top: 5px;
      position: relative;
      color: rgba(255, 255, 255, .8); } }
  .popular-cities {
    display: flex;
    a {
      text-decoration: none;
      width: 33.33%; }
    .intro {
      padding: 20% 0;
      &:hover {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          background: rgba(0, 0, 0, .7); } } } }
  h3 {
    background: $darken-color;
    margin: 0;
    padding: 20px; }
  .more-cities {
    a {
      color: inherit;
      text-decoration: none; } }
  @media only screen and (max-width: 768px) {
    .popular-cities {
      flex-direction: column;
      a {
        width: 100%; } } } }
