@import '../../assets/sass/style';

header {
  background: $darken-color;
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  ul {
    margin: 0;
    padding: 0;
    display: flex; }
  li, #logo {
    display: flex;
    justify-self: flex-end;
    transition: .2s;
    &:hover:not(#logo):not(.auth-button) {
      background: lighten($darken-color, 2);
      a {
        color: darken($light-color, 5);
        svg path {
          fill: darken($light-color, 5); } } }
    a {
      color: $light-color;
      text-decoration: none;
      display: flex;
      align-items: center;
      transition: .2s;
      padding: 15px 20px;
      svg {
        width: 22px;
        margin-right: 7px; } } }
  li.auth-button {
    background: linear-gradient($medium-color, lighten($medium-color, 5));
    margin: 10px 30px 10px 20px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    transition: .2s;
    &:hover {
      background: linear-gradient(lighten($medium-color, 5), lighten($medium-color, 10)); }
    a {
      padding: 10px 25px;
      transition: .2s; } }
  .mobile-toggler {
    background: none;
    border: none;
    font-size: 22px;
    color: $light-color;
    padding-right: 20px;
    display: none; }
  @media only screen and (max-width: 768px) {
    height: 49px;
    ul {
      position: absolute;
      top: 49px;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 2;
      flex-direction: column;
      align-items: center;
      background: $dark-color;
      display: none;
      &.opened-menu {
        display: flex; } }
    li.auth-button {
      margin: 10px 0; }
    .mobile-toggler {
      display: block; } } }
